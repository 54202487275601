<template>
  <div id="MainHeader" class="fixed top-0 left-0 z-30 w-full">
    <Announcement ref="Announcement" />
    <div class="relative">
      <div class="w-full relative z-10 h-[70px]">
        <div
          class="relative z-10 flex items-center justify-between w-full h-full px-5"
        >
          <router-link
            to="/"
            class="relative z-10 flex items-center h-full transform"
          >
            <img src="/img/logo.webp" class="block h-[50%]" />
          </router-link>

          <div class="flex items-center h-full">
            <ol class="items-center hidden h-full mr-2 lg:flex">
              <li
                v-for="(item, item_index) in menu_list"
                :key="`list_item_${item_index}`"
                class="flex items-center h-full px-1"
              >
                <router-link
                  v-if="item.type == 'item'"
                  class="flex items-center px-3 py-1 text-sm text-white transition-colors duration-300 bg-transparent rounded-md md:hover:bg-black md:hover:bg-opacity-50 hover:md:text-white"
                  :to="item.link"
                >
                  <span class="text-center">
                    <span
                      class="block text-base font-bold tracking-wider uppercase"
                      >{{ item.sub_title }}</span
                    >
                    <span class="block font-bold tracking-wider text-primary">{{
                      item.title
                    }}</span>
                  </span>
                </router-link>
                <div
                  v-else
                  class="relative flex items-center px-3 py-1 text-sm text-white transition-colors duration-300 bg-transparent rounded-md cursor-pointer drop_down md:hover:bg-black md:hover:bg-opacity-50 hover:md:text-white"
                >
                  <span class="text-center">
                    <span
                      class="block text-base font-bold tracking-wider uppercase"
                      >{{ item.sub_title }}</span
                    >
                    <span class="block font-bold tracking-wider text-primary">{{
                      item.title
                    }}</span>
                  </span>
                  <DropDown ref="DropDown" />
                </div>
              </li>
            </ol>
            <ol class="flex items-center h-full lg:-mr-2">
              <!-- <li class="hidden h-full lg:block">
                <button
                  @click="OpenSearchDialog"
                  class="flex items-center justify-center w-6 h-full px-5 text-white transition-colors duration-300 hover:md:text-primary"
                >
                  <i class="text-current icon-magnifying-glass"></i>
                </button>
              </li> -->
              <li class="items-center hidden h-full mr-2 md:flex">
                <button
                  @click="$emit('open-language-dialog')"
                  class="relative md:hover:text-primary transition-all duration-300 py-[5px] pl-3 text-sm font-bold text-left rounded-sm w-20 bg-zinc-300"
                >
                  語言
                  <i
                    class="absolute z-10 text-xs text-current transform scale-90 -translate-y-1/2 pointer-events-none top-1/2 right-2 icon-chevron-down"
                  ></i>
                </button>
              </li>
              <li class="flex items-center h-full mr-3 lg:mr-0">
                <button
                  @click="$store.commit('SetShopCartStatus', true)"
                  class="relative flex items-center justify-center w-10 h-10 text-white transition-colors duration-300 border rounded-full lg:w-auto lg:bg-transparent bg-primary lg:border-none border-zinc-800 lg:h-full lg:px-2 hover:lg:text-primary"
                >
                  <div class="relative w-6 text-current">
                    <span
                      class="absolute -right-2 lg:-right-1 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block text-white transform rounded-full -top-2 lg:-top-1 lg:bg-primary bg-zinc-900 scale-[.9]"
                      >{{ shop_cart_count }}</span
                    >
                    <i
                      class="relative z-0 text-xl text-current icon-cart-shopping"
                    ></i>
                  </div>
                </button>
              </li>
              <li class="flex items-center h-full lg:hidden">
                <button
                  @click="$emit('menu-action')"
                  class="flex items-center justify-center w-10 h-10 text-white transition-colors duration-300 border rounded-full border-primary bg-zinc-800 lg:hidden hover:md:bg-primary"
                >
                  <i v-show="!menu_status" class="text-current icon-menu"></i>
                  <i v-show="menu_status" class="text-current icon-close"></i>
                </button>
              </li>
            </ol>
          </div>
        </div>

        <div class="absolute top-0 bottom-0 left-0 right-0 z-0">
          <div
            class="w-full bg-gradient-to-b from-[rgba(0,0,0,.9)] to-transparent h-full"
          ></div>
        </div>
      </div>
      <ShopCartMessage ref="ShopCartMessage" />
    </div>
  </div>
</template>

<script>
import Announcement from "@/components/mainHeader/Announcement.vue";
import ShopCartMessage from "@/components/mainHeader/ShopCartMessage.vue";
import DropDown from "@/components/mainHeader/DropDown.vue";
export default {
  name: "MainHeader",
  components: {
    Announcement,
    ShopCartMessage,
    DropDown,
  },
  data() {
    return {
      menu_list: [
        {
          title: "關於我們",
          sub_title: "About",
          link: "/about",
          type: "item",
        },
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/scooters/all",
          type: "dropdown",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video/list",
          type: "item",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
          type: "item",
        },
        {
          title: "客服中心",
          sub_title: "Support",
          link: "/support",
          type: "item",
        },
        {
          title: "經銷據點",
          sub_title: "Dealers",
          link: "/dealers",
          type: "item",
        },
      ],
      drop_down: false,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
  },
  computed: {
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  mounted() {
    const dropDowns = document.querySelectorAll(".drop_down");
    document.addEventListener("click", function (e) {
      dropDowns.forEach((dropDown) => {
        const drop_down_box = dropDown.querySelector(".drop_down_box");
        if (dropDown.contains(e.target)) {
          if (e.target.classList.contains("drop_down_item")) {
            drop_down_box.classList.remove("active");
          } else {
            drop_down_box.classList.add("active");
          }
        } else {
          drop_down_box.classList.remove("active");
        }
      });
    });
  },
};
</script>
