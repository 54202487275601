<template>
  <div v-if="dialog" class="fixed top-0 bottom-0 left-0 right-0 z-50">
    <div class="relative w-full h-full">
      <a
        href="https://www.apexxfactory.com.tw/"
        @mouseenter="hover_index = 0"
        @mouseleave="hover_index = -1"
        id="LanguageLeftBox"
        class="absolute top-0 left-0 w-full md:w-[58%] h-[58%] bg-black md:h-full overflow-hidden"
      >
        <div
          class="absolute transform -translate-x-1/2 max-w-[80%] z-10 -translate-y-1/2 top-1/2 left-1/2 w-72 md:w-96"
        >
          <img
            src="/img/language/apexx_factory.webp"
            class="w-full block transform md:-translate-y-0 -translate-y-[20%] md:-translate-x-[15%] drop-shadow-md"
          />
        </div>
        <img
          src="/img/language/APEXX_首頁工作區域 2.webp"
          class="relative z-0 object-cover w-full h-full transition-all duration-500 transform opacity-50"
          :class="hover_index == 0 ? 'scale-110' : ''"
        />
      </a>
      <a
        @click="CloseDialog"
        @mouseenter="hover_index = 1"
        @mouseleave="hover_index = -1"
        id="LanguageRightBox"
        class="absolute bottom-0 top-auto md:top-0 right-0 bg-black w-full md:w-[58%] h-[58%] md:h-full overflow-hidden"
      >
        <div
          class="absolute transform -translate-x-1/2 max-w-[80%] z-10 -translate-y-1/2 top-1/2 left-1/2 w-72 md:w-96"
        >
          <img
            src="/img/language/apexx_racing.webp"
            class="w-full block transform md:-translate-y-0 translate-y-[20%] md:translate-x-[15%] drop-shadow-md"
          />
        </div>
        <img
          src="/img/language/APEXX_首頁工作區域 1.webp"
          class="relative z-0 object-cover w-full h-full transition-all duration-500 transform opacity-50"
          :class="hover_index == 1 ? 'scale-110' : ''"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageDialog",
  data() {
    return {
      dialog: false,
      hover_index: -1,
    };
  },
  methods: {
    CloseDialog() {
      this.dialog = false;
    },
    OpenDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style>
#LanguageLeftBox {
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}
#LanguageRightBox {
  clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0 100%);
}

@media (max-width: 768px) {
  #LanguageLeftBox {
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
  }
  #LanguageRightBox {
    clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0 100%);
  }
}
</style>
