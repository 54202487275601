<template>
  <section
    id="HomeProductCollections"
    ref="MainContent"
    class="mb-20 overflow-hidden main_container"
  >
    <header class="mb-10">
      <p data-section-sub-title class="mb-0 text-lg font-bold text-primary">
        COLLECTIONS
      </p>
      <h3 data-section-title class="text-3xl font-black text-white sm:text-5xl">
        精選分類
      </h3>
    </header>
    <div class="flex items-stretch -mx-5">
      <div class="relative hidden w-1/2 p-3 lg:block">
        <div class="relative w-full h-full overflow-hidden collection_card">
          <a
            :href="$GetColumn('home_collection_1_link')"
            data-card-cover
            class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
          >
            <div class="text-center">
              <h4 class="text-3xl font-black text-white">
                {{ $GetColumn("home_collection_1_title") }}
              </h4>
              <p class="text-lg font-bold uppercase text-primary">
                {{ $GetColumn("home_collection_1_sub_title") }}
              </p>
            </div>
          </a>
          <div
            class="relative z-0 w-full overflow-hidden aspect-square image_box"
          >
            <LazyLoadingImage
              data-card-image
              :src="$ImageUrl($GetColumn('home_collection_1_image'))"
              class="block object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div class="w-full p-3 lg:w-1/2">
        <div class="flex flex-wrap items-stretch justify-between -m-3">
          <div class="block w-full p-3 sm:w-1/2 lg:hidden sm:h-1/2">
            <div class="relative w-full h-full overflow-hidden collection_card">
              <a
                :href="$GetColumn('home_collection_1_link')"
                data-card-cover
                class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div class="text-center">
                  <h4 class="text-2xl font-black text-white">
                    {{ $GetColumn("home_collection_1_title") }}
                  </h4>
                  <p
                    class="text-sm font-bold uppercase xl:text-lg text-primary"
                  >
                    {{ $GetColumn("home_collection_1_sub_title") }}
                  </p>
                </div>
              </a>
              <div class="relative z-0 w-full h-full image_box">
                <LazyLoadingImage
                  data-card-image
                  :src="$ImageUrl($GetColumn('home_collection_2_image'))"
                  class="block object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
          <div class="w-full p-3 sm:w-1/2 sm:h-1/2">
            <div class="relative w-full h-full overflow-hidden collection_card">
              <a
                :href="$GetColumn('home_collection_2_link')"
                data-card-cover
                class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div class="px-10 text-center">
                  <h4 class="text-2xl font-black text-white xl:text-3xl">
                    {{ $GetColumn("home_collection_2_title") }}
                  </h4>
                  <p
                    class="text-sm font-bold uppercase xl:text-lg text-primary"
                  >
                    {{ $GetColumn("home_collection_2_sub_title") }}
                  </p>
                </div>
              </a>
              <div class="relative z-0 w-full h-full image_box">
                <LazyLoadingImage
                  data-card-image
                  :src="$ImageUrl($GetColumn('home_collection_2_image'))"
                  class="block object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
          <div class="w-full p-3 sm:w-1/2 sm:h-1/2">
            <div class="relative w-full h-full overflow-hidden collection_card">
              <a
                :href="$GetColumn('home_collection_3_link')"
                data-card-cover
                class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div class="px-10 text-center">
                  <h4 class="text-2xl font-black text-white xl:text-3xl">
                    {{ $GetColumn("home_collection_3_title") }}
                  </h4>
                  <p
                    class="text-sm font-bold uppercase xl:text-lg text-primary"
                  >
                    {{ $GetColumn("home_collection_3_sub_title") }}
                  </p>
                </div>
              </a>
              <div class="relative z-0 w-full h-full image_box">
                <LazyLoadingImage
                  data-card-image
                  :src="$ImageUrl($GetColumn('home_collection_3_image'))"
                  class="block object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
          <div class="w-full p-3 sm:w-1/2 sm:h-1/2">
            <div class="relative w-full h-full overflow-hidden collection_card">
              <a
                :href="$GetColumn('home_collection_4_link')"
                data-card-cover
                class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div class="px-10 text-center">
                  <h4 class="text-2xl font-black text-white xl:text-3xl">
                    {{ $GetColumn("home_collection_4_title") }}
                  </h4>
                  <p
                    class="text-sm font-bold uppercase xl:text-lg text-primary"
                  >
                    {{ $GetColumn("home_collection_4_sub_title") }}
                  </p>
                </div>
              </a>
              <div class="relative z-0 w-full h-full image_box">
                <LazyLoadingImage
                  data-card-image
                  :src="$ImageUrl($GetColumn('home_collection_4_image'))"
                  class="block object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
          <div class="w-full p-3 sm:w-1/2 sm:h-1/2">
            <div class="relative w-full h-full overflow-hidden collection_card">
              <a
                :href="$GetColumn('home_collection_5_link')"
                data-card-cover
                class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div class="px-10 text-center">
                  <h4 class="text-2xl font-black text-white xl:text-3xl">
                    {{ $GetColumn("home_collection_5_title") }}
                  </h4>
                  <p
                    class="text-sm font-bold uppercase xl:text-lg text-primary"
                  >
                    {{ $GetColumn("home_collection_5_sub_title") }}
                  </p>
                </div>
              </a>
              <div class="relative z-0 w-full h-full image_box">
                <LazyLoadingImage
                  data-card-image
                  :src="$ImageUrl($GetColumn('home_collection_5_image'))"
                  class="relative z-0 block object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LazyLoadingImage from "@/components/LazyLoadingImage.vue";
import { ProductCollection } from "@/gsap/home/ProductCollection";
export default {
  name: "HomeProductCollections",
  components: {
    LazyLoadingImage,
  },
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new ProductCollection(this.$refs.MainContent);
    this.section_gsap.reset();
    this.section_gsap.setup();
  },
};
</script>

<style>
.collection_card .image_box {
  transform: scale(1);
  transition: all 0.3s;
}
.collection_card:hover .image_box {
  transform: scale(1.1);
}
</style>
