<template>
  <section
    id="HomeMainProduct"
    ref="MainContent"
    class="overflow-hidden md:py-10 main_container"
  >
    <div
      class="relative flex flex-col-reverse flex-wrap items-center justify-between py-10 -mx-10 md:flex-row md:px-5"
    >
      <div
        class="relative z-10 flex items-center w-full p-10 sm:p-20 md:p-10 md:w-1/2"
      >
        <div data-section-content class="relative z-10">
          <p class="mb-0 font-bold sm:text-lg text-primary">PRODUCT</p>
          <div class="relative mb-5">
            <h3
              class="text-3xl font-black text-white sm:text-5xl"
              v-html="$GetColumn('home_promote_product_2_title')"
            ></h3>
          </div>
          <div
            class="mb-10 text-sm font-medium text-zinc-400"
            v-html="
              $GetColumn('home_promote_product_2_content').replace(
                /\r?\n/g,
                '<br />'
              )
            "
          ></div>
          <div>
            <a
              :href="$GetColumn('home_promote_product_2_link')"
              class="inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black md:text-base bg-primary md:hover:bg-opacity-100"
              >查看商品
              <i class="block ml-3 transform -rotate-45 icon-arrow-right"></i>
            </a>
          </div>
        </div>

        <div
          class="absolute z-0 transform -translate-y-1/2 opacity-10 md:opacity-20 right-10 top-1/2"
        >
          <LazyLoadingImage
            data-section-bg
            src="/img/logo_icon.svg"
            class="block w-52"
          />
        </div>

        <div
          class="absolute z-0 hidden sm:block md:top-0 md:bottom-0 md:left-0 md:right-0 top-10 left-10 right-10 bottom-10"
        >
          <div data-section-frame class="absolute top-0 left-0">
            <i class="block w-5 h-[2px] md:h-[3px] bg-zinc-600"></i>
            <i class="block md:w-[3px] w-[2px] h-5 bg-zinc-600"></i>
          </div>
          <div data-section-frame class="absolute top-0 right-0">
            <i class="block w-5 h-[2px] md:h-[3px] bg-zinc-600"></i>
            <i class="block md:w-[3px] w-[2px] h-5 ml-auto bg-zinc-600"></i>
          </div>

          <div data-section-frame class="absolute bottom-0 left-0">
            <i class="block md:w-[3px] w-[2px] h-5 bg-zinc-600"></i>
            <i class="block w-5 h-[2px] md:h-[3px] bg-zinc-600"></i>
          </div>
          <div data-section-frame class="absolute bottom-0 right-0">
            <i class="block md:w-[3px] w-[2px] h-5 ml-auto bg-zinc-600"></i>
            <i class="block w-5 h-[2px] md:h-[3px] bg-zinc-600"></i>
          </div>
        </div>
      </div>
      <div class="relative z-10 w-full px-10 md:w-1/2">
        <div class="w-full">
          <LazyLoadingImage
            data-section-image
            :src="$ImageUrl($GetColumn('home_promote_product_2_image'))"
            class="block object-cover w-full"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LazyLoadingImage from "@/components/LazyLoadingImage.vue";
import { MainProductSection } from "@/gsap/home/MainProductSection";
export default {
  name: "HomeMainProduct",
  components: {
    LazyLoadingImage,
  },
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new MainProductSection(this.$refs.MainContent);
    this.section_gsap.reset();
    this.section_gsap.setup();
  },
};
</script>
