import Vue from "vue";
import Vuex from "vuex";
import { getAllData } from "@/api/page_data.js";
import { shopcart_module } from "./shopcart";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    main_dialog: {
      status: false, //系統訊息顯示狀態
      content: "", //系統訊息內容
    },
    image_loaded: false, //頁面圖片讀取狀態
    loading: 0, //Loading狀態，大於0則為讀取中，等於0則為讀取完畢
    shop_cart_open: false, //購物車抽屜打開狀態
    shop_cart_dialog: 0, //加入購物車訊息狀態
    search_dialog: false, //搜尋視窗打開狀態
    // api資料
    common_column_data: null, //通用欄位資料
    carousel_data: null, //首頁輪播資料
    news_data: null, //最新消息資料
    news_category_data: null, //最新消息分類資料
    video_data: null, //影音專區資料
    video_category_data: null, //影音專區分類資料
    menu_category_data: null, //商品目錄資料
    category_data: null, //商品分類資料
    event_data: null, //獨立銷售頁資料
    all_product_data: null, //所有商品資料，包含已刪除以及停用的商品
    product_data: null, //商品資料
    zipcode_data: null, //縣市郵遞區號資料
    payment_data: null, //付款方式資料
    shipway_data: null, //物流方式資料
    page_meta_data: null, //頁面meta資料
    question_data: null, //常見問題資料
    dealer_data: null, //經銷商資料
  },
  getters: {
    // 取得資料讀取狀況
    all_data_loaded(state) {
      if (
        state.common_column_data != null &&
        state.carousel_data != null &&
        state.news_data != null &&
        state.news_category_data != null &&
        state.category_data != null &&
        state.product_data != null &&
        state.zipcode_data != null &&
        state.payment_data != null &&
        state.shipway_data != null &&
        state.menu_category_data != null &&
        state.video_data != null &&
        state.video_category_data != null &&
        state.page_meta_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 取得指定ID的獨立銷售頁資料
    event_data: (state) => (key) => {
      if (state.event_data == null) {
        return null;
      }
      const event_data = state.event_data.filter((item) => item.MenuID == key);
      if (event_data.length <= 0) {
        return "error";
      }

      return event_data.length > 0 ? event_data[0] : "error";
    },
    // 取得指定分類的最新消息
    filter_news_data: (state) => (category_id) => {
      return category_id == ""
        ? state.news_data
        : state.news_data.filter((item) => item.NewsCategoryID == category_id);
    },
    // 取得指定ID的最新消息
    active_news_data: (state) => (news_id) => {
      if (state.news_data == null) {
        return null;
      }
      const active_news_data = state.news_data.filter(
        (item) => item.NewsID == news_id
      );
      return active_news_data.length > 0 ? active_news_data[0] : "error";
    },
    // 取得指定ID的最新消息分類
    active_news_category_data: (state) => (category_id) => {
      const active_category = state.news_category_data.filter(
        (item) => item.NewsCategoryID == category_id
      );
      return active_category.length > 0 ? active_category[0] : "error";
    },
    // 取得指定ID的商品分類
    filter_category_data: (state) => (category_id) => {
      const category = state.category_data.filter(
        (item) => item.MenuID == category_id
      );

      return category.length > 0 ? category[0] : null;
    },
    // 取得指定分類的商品
    filter_product_data: (state) => (category_id) => {
      if (state.product_data == null) {
        return null;
      }
      if (category_id == "all" || category_id == "") {
        return state.product_data;
      } else {
        return state.product_data.filter((item) => {
          return (
            item.Menu.filter((menu) => menu.MenuID == category_id).length > 0
          );
        });
      }
    },
    // 取得header公告
    announcement_list: (state) => {
      let list = [];
      for (let i = 0; i < 10; i++) {
        const content = state.common_column_data.filter(
          (item) => item.ColumnKey == `event_announcement_${i + 1}`
        )[0].Content;
        const link = state.common_column_data.filter(
          (item) => item.ColumnKey == `event_announcement_link_${i + 1}`
        )[0].Content;
        content != ""
          ? list.push({
              content: content,
              link: link,
            })
          : "";
      }
      return list;
    },
    // 取得首頁大圖輪播
    home_cover_carousel: (state) => {
      return state.carousel_data.filter((item) => item.CarouselCategoryID == 1);
    },
    // 取得首頁推薦商品
    home_promote_products: (state) => {
      const category = state.category_data.filter((item) => item.MenuID == 8);
      if (category.length <= 0) {
        return [];
      } else {
        const category_id = category[0].MenuID;
        return state.product_data.filter((item) => {
          return (
            item.Menu.filter((menu) => menu.MenuID == category_id).length > 0
          );
        });
      }
    },
    // 取得首頁相簿輪播
    home_gallery_carousel: (state) => {
      return state.carousel_data.filter((item) => item.CarouselCategoryID == 2);
    },
    // 取得指定的Meta資料
    page_meta_data: (state) => (page_title) => {
      return state.page_meta_data.filter(
        (item) => item.PageTitle == page_title
      )[0];
    },
  },
  mutations: {
    // 設定商品搜尋視窗狀態
    SetSearchDialog(state, action) {
      state.search_dialog = action;
    },
    // 設定加入購物車訊息狀態
    SetShopCartDialog(state, action) {
      let index = (state.shop_cart_dialog += action);
      state.shop_cart_dialog = index < 0 ? 0 : index;
    },
    // 設定購物車打開狀態
    SetShopCartStatus(state, action) {
      state.shop_cart_open = action;
    },
    // 設定頁面圖片讀取狀態
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    // 設定Loading狀態
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : "";
      }
    },
    // 設定Dialog狀態與內容文字
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    // 設定State資料
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData(state) {
      getAllData().then((res) => {
        state.commit("SetStateData", {
          key: "page_meta_data",
          val: res.data.PageMetaData.data,
        });
        state.commit("SetStateData", {
          key: "common_column_data",
          val: res.data.PageEdit.data,
        });
        state.commit("SetStateData", {
          key: "zipcode_data",
          val: res.data.ZipCode.data,
        });
        state.commit("SetStateData", {
          key: "carousel_data",
          val: res.data.Carousel.data,
        });
        state.commit("SetStateData", {
          key: "news_category_data",
          val: res.data.NewsCategory.data,
        });
        state.commit("SetStateData", {
          key: "payment_data",
          val: res.data.Payment.data,
        });
        state.commit("SetStateData", {
          key: "shipway_data",
          val: res.data.Shipping.data,
        });
        state.commit("SetStateData", {
          key: "video_data",
          val: res.data.Video.data,
        });
        state.commit("SetStateData", {
          key: "video_category_data",
          val: res.data.VideoCategory.data,
        });
        state.commit("SetStateData", {
          key: "question_data",
          val: res.data.Question.data,
        });
        state.commit("SetStateData", {
          key: "dealer_data",
          val: res.data.Dealer.data,
        });

        state.dispatch("getNewsData", res.data.News.data);
        state.dispatch("getCategoryData", res.data.Menu.data);
        state.dispatch("getParentCategoryData", res.data.MenuCategory.data);
        state.dispatch("getProductData", res.data.Goods.data);
      });
    },
    // 取得最新消息資料
    getNewsData(state, data) {
      let tmp_data = data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      state.commit("SetStateData", {
        key: "news_data",
        val: tmp_data,
      });
    },
    // 取得商品分類與獨立銷售頁資料
    getCategoryData(state, data) {
      // 依照後台設定的排序，排序資料
      let tmp_data = data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });

      // 商品分類為Content5不等於獨立銷售頁的資料
      let category = tmp_data.filter((item) => item.Content5 != "獨立銷售頁");
      state.commit("SetStateData", {
        key: "category_data",
        val: category,
      });

      // 篩選有設定限制時間，且已經超出時間範圍的資料
      category = category.filter((item) => {
        if (item.MenuTimeEnd != null) {
          return new Date() < new Date(item.MenuTimeEnd);
        } else {
          return true;
        }
      });

      // 獨立銷售頁為Content5等於獨立銷售頁的資料
      const event = tmp_data.filter((item) => item.Content5 == "獨立銷售頁");
      state.commit("SetStateData", {
        key: "event_data",
        val: event,
      });
    },
    // 取得商品目錄
    getParentCategoryData({ commit }, data) {
      // 篩選不顯示的目錄
      let tmp_data = data.filter((item) => {
        return item.Status != "N";
      });
      commit("SetStateData", {
        key: "menu_category_data",
        val: tmp_data,
      });
    },
    // 取得商品資料
    getProductData({ commit }, data) {
      // 篩選已刪除商品
      let tmp_data = data.filter((item) => {
        return item.deleted_at == null;
      });
      // 篩選停用的庫存，並判斷若商品沒有圖片則顯示預設圖片
      tmp_data.forEach((item, item_index) => {
        tmp_data[item_index].Stock = item.Stock.filter(
          (stock) => stock.Status == "Y"
        );
        if (item.Image1 == "") {
          tmp_data[item_index].Image1 = "/image/product_default.webp";
        }
      });
      // 篩選掉沒有庫存選項的一般商品
      tmp_data = tmp_data.filter(
        (item) => item.Stock.length > 0 || item.IsCustom == "Y"
      );
      // 篩選停用的商品
      tmp_data = tmp_data.filter((item) => item.Status == "Y");
      // 排序
      tmp_data = tmp_data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      commit("SetStateData", {
        key: "all_product_data",
        val: data,
      });
      commit("SetStateData", {
        key: "product_data",
        val: tmp_data,
      });
      commit("shopcart_module/SetProductData", tmp_data, {
        root: true,
      });
    },
  },
  modules: {
    shopcart_module: shopcart_module,
  },
});

export default store;
