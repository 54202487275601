<template>
  <div
    class="fixed left-0 z-50 w-full overflow-y-auto transition-all duration-500 transform top-full"
    :class="enable_cookie ? '-translate-y-0' : '-translate-y-full'"
  >
    <div
      class="flex flex-wrap items-center justify-between w-full px-5 py-5 bg-primary md:px-10 md:flex-nowrap"
    >
      <p
        class="block w-full mb-5 text-sm text-white md:text-base md:w-auto md:mb-0"
      >
        我們使用 Cookie
        來確保您在網站上能獲得良好的體驗。如果您繼續使用本網站，我們將認定您接受並理解我們的
        <router-link
          class="font-bold text-black underline"
          to="/terms/site_privacy"
          >隱私政策</router-link
        >
        和
        <router-link
          class="font-bold text-black underline"
          to="/terms/terms_of_customer"
          >使用者條款</router-link
        >
        。
      </p>

      <div class="flex-shrink-0 w-full text-center md:ml-5 md:w-auto">
        <button
          @click="AspectConsent"
          class="inline-flex items-center py-2 pl-8 pr-6 text-base font-bold text-white transition-colors duration-500 bg-black border border-black md:hover:bg-opacity-50"
        >
          接受
          <i class="block ml-3 transform -rotate-45 icon-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { GTM_UpdateConsent } from "@/common/gtm_methods";
export default {
  name: "CookieConsentDialog",
  data() {
    return {
      enable_cookie: false,
    };
  },
  methods: {
    AspectConsent() {
      this.enable_cookie = true;
      GTM_UpdateConsent(true);
    },
  },
};
</script>
